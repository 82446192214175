import { useCsrfToken } from '../use-csrf-token'

// this code has no test coverage. It's impossible to write acceptance tests against Stripe, and
// we could write unit tests here, but we have not. We should instead manually test this code extensively
// in the browser to ensure it works as expected.

/* global Stripe */

export function setupIntent() {
  const paymentFormElem = document.getElementById('payment-form')
  const options = {
    clientSecret: paymentFormElem.dataset.clientSecret,
    // Fully customizable with appearance API.
    appearance: {/*...*/}
  }

  const stripe = Stripe(paymentFormElem.dataset.stripeKey)
  const elements = stripe.elements(options)
  // Create and mount the Payment Element
  const paymentElement = elements.create('payment')
  paymentElement.mount('#payment-element')

  const form = document.getElementById('payment-form')
  form.addEventListener('submit', async (event) => {
    event.preventDefault()
    // clear existing errors.
    document.getElementById('daisychain-errors').innerHTML = ''

    if (document.getElementById('agree_plan_checkbox').checked) {
      const {error} = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: paymentFormElem.dataset.returnUrl
        }
      })

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        const messageContainer = document.querySelector('#error-message')
        messageContainer.innerHTML = `<div class="notification error">${error.message}</div>`
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } else {
      document.getElementById('daisychain-errors').innerHTML = '<div class="notification error">You must agree to the platform subscription and messaging rates to continue.</div>'
    }
  })
}

export function verifyIntent() {
  const paymentStatusDiv = document.getElementById('payment-status')
  const stripe = Stripe(paymentStatusDiv.dataset.stripeKey)
  const billingAgreementPath = paymentStatusDiv.dataset.billingAgreementPath
  const accountPath = paymentStatusDiv.dataset.accountPath

  const clientSecret = new URLSearchParams(window.location.search).get(
    'setup_intent_client_secret'
  )

  stripe.retrieveSetupIntent(clientSecret).then(({setupIntent}) => {
    if (setupIntent.status === 'succeeded') {
      const csrfToken = useCsrfToken()

      fetch(billingAgreementPath, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({setup_intent_id: setupIntent.id, stripe_payment_method_id: setupIntent.payment_method})
      }).then(response => {
        if (!response.ok) {
          throw response
        }
        response.text()
      }).then((_data) => {
        window.location.assign(accountPath)
      }).catch(error => {
        console.error('error', error)
      })
    } else if (setupIntent.status === 'processing') {
      // Handle processing state
      paymentStatusDiv.innerHTML = '<div class="notification info">Payment setup processing. Hold tight, we\'ll be in touch when your payment method is confirmed.</div>'
    } else {
      // Handle unsuccessful or unknown payment status
      paymentStatusDiv.innerHTML = '<div class="notification error">Payment failed.</div>'
    }
  })
}
