import Rails from '@rails/ujs'
import { setupIntent, verifyIntent } from '../utils/stripe/setup-intent'

if(!window._rails_loaded) {
  Rails.start()
}
document.addEventListener('DOMContentLoaded', (_event) => {
  const noScanLink = document.getElementById('no-scan')

  if(noScanLink) {
    noScanLink.addEventListener('click', (_event) => {
      document.getElementById('otp-secret').classList.toggle('hidden')
    })
  }

  if (document.getElementById('payment-form')) {
    setupIntent()
  }

  if (document.getElementById('payment-status')) {
    verifyIntent()
  }

  const numberKindLocal = document.getElementById('number_kind_local')
  const einField = document.getElementById('ein-field')
  const radioContainer = document.getElementById('number_kind-radio-cards-container')

  if(radioContainer) {
    radioContainer.addEventListener('change', function (e) {
      if (e.target && e.target.matches('input[type="radio"]')) {
        if (numberKindLocal.checked) {
          einField.classList.remove('hidden')

        } else {
          einField.classList.add('hidden')
        }
      }
    })
  }

})


